export const environment = {
    production: false,
    HTTP_PROTOCOL: 'https',
    SITE_DOMAIN: 'stage.boodmo.dev',
    PLATFORM_HOSTNAME: '/api',
    SELLER_HOSTNAME : 'seller.stage.boodmo.dev',
    SHARED_DOMAIN_NAME : 'stage.boodmo.dev',
    ESB_SERVER: 'https://esb.stage.boodmo.dev',
    CRM_SERVER: 'https://crm.stage.boodmo.dev',
    DOCUMENT_SERVER: 'https://pdf.stage.boodmo.dev',
    ADMIN_VIEW_SERVER: 'https://adminview.stage.boodmo.dev',
    firebase: {
		"apiKey": "AIzaSyBEm2Bofe9NdTwgCVF7YSsP3MQvR8rD8_E",
		"authDomain": "auth.stage.boodmo.dev",
		"databaseURL": "https://boodmo-stage-notifications.asia-southeast1.firebasedatabase.app/",
		"projectId": "infrastructure-211207",
		"storageBucket": "shipment-media-stage",
		"messagingSenderId": "800866803584",
		"appId": "1:800866803584:web:df7e8a253213c8bdfbab65",
		"locationId": "us-central"
},
    firebaseRemoteConfig: {
        minimumFetchIntervalMillis: 60000,
    },
    firebaseDatabases: [
		"https://boodmo-stage-notifications.asia-southeast1.firebasedatabase.app/",
		"https://boodmo-stage-smart-grid-presets.asia-southeast1.firebasedatabase.app/",
		"https://infrastructure-211207.firebaseio.com",
		"http://127.0.0.1:9000/?ns=demo-infrastructure-211207"
],
    storageBuckets: {
		"shipmentMedia": "shipment-media-stage",
		"customerReturns": "customer-returns-stage",
		"boodmoFileImports": "boodmo-file-imports-stage"
},
    googleMapsKey: 'AIzaSyDGSmYHIXCCTwP61yO1I9rPrtnVFQfsNTI',
};
